import { Fragment, useState } from 'react';
import Home from './Pages/Home';
import WieZijnWij from './Pages/WieZijnWij';
import Intake from './Pages/Intake';
import Kosten from './Pages/Kosten';
import Ervaringen from './Pages/Ervaringen';
import Aanvragen from './Pages/Aanvragen';
import Faq from './Pages/Faq';
import Contact from './Pages/Contact';
import Vacancy from './Pages/Vacancy';
import VacancyLeerling from './Pages/VacancyLeerling';
import ApplyForJob from './Pages/ApplyForJob';
import Page404 from './Pages/Page404';
import { Routes, Route, Redirect } from 'react-router-dom';
import Bedankt from './Pages/Bedankt';
import GeneralConditions from './Pages/GeneralConditions';
import NotWorkingZipcode from './Pages/NotWorkingZipcode';
import MamaPas from './Pages/MamaPas';
import BedanktSollicitation from './Pages/BedanktSollicitation';
import VacancyNew from './Pages/VacancyNew';
import Privacy from './Pages/Privacy';
import { Helmet } from 'react-helmet';

// #DB1F6A

function App() {


  return (
    <div className="md:container-fluid  min-h-screen relative font-ro-sans leading-normal">
       <Routes>
		   <Route path="/">
				<Route index element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Jouw Regio | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home />
				</Fragment>} />
				<Route path="/kraamzorg-denhaag" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Den Haag | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home place="Den Haag" seaPlace="Den Haag" />
				</Fragment>} />
				<Route path="/kraamzorg-rotterdam" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Rotterdam | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home place="Rotterdam" seaPlace="Rotterdam" />
				</Fragment>} />
				<Route path="/kraamzorg-utrecht" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Jouw Regio | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home />
				</Fragment>} />
				<Route path="/den-haag" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Den Haag | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
						<meta name="robots" content="none" />
					</Helmet>
					<Home seaPlace="Den Haag" />
				</Fragment>} />
				<Route path="/rotterdam" element={<Fragment>
					<Helmet >
						<title>De Beste Kraamzorg In Rotterdam | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
						<meta name="robots" content="none" />
					</Helmet>
					<Home seaPlace="Rotterdam" />
				</Fragment>} />
				<Route path="/utrecht" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Jouw Regio | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home />
				</Fragment>} />
				<Route path="/wie-zijn-wij.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | Waarom Zijn Wij De Beste?</title>
						<meta name="description" content="Kraamzorg Mama is een Kraamzorgbureau dat al jaren goed staat aangeschreven bij cliënten, verloskundigen en alle zorgverzekeraars" />
					</Helmet>
					<WieZijnWij />
				</Fragment>} />
				<Route path="/intakegesprek.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | Lees Alles Over De Intake En Kraamtijd</title>
						<meta name="description" content="Wij werken alleen met ervaren kraamverzorgenden. Tijdens de Intake nemen we al je wensen door om onze zorg op af te stemmen." />
					</Helmet>
					<Intake />
				</Fragment> } />
				<Route path="/verzekering.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | De Kosten en Verzekering van de Kraamzorg</title>
						<meta name="description" content="De eigen bijdrage is landelijk bepaald en geldt dus voor alle kraambureaus in Nederland. Kom je er niet uit? Wij helpen je graag!" />
					</Helmet>
					<Kosten />
				</Fragment>} />
				<Route path="/ervaringen" element={<Fragment>
					<Helmet>
						<title>Waarom Zijn Wij De Beste? | Lees De Ervaringen</title>
						<meta name="description" content="Meer dan 50.000 moeders gingen je voor! Lees de ervaringen van andere moeders" />
					</Helmet>
					<Ervaringen />
				</Fragment>} />
				<Route path="/kraamzorg-aanvragen.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | Kraamzorg Aanvragen? </title>
						<meta name="description" content="Wij adviseren je om je aan te melden voor de 12e week van je zwangerschap." />
					</Helmet>
					<Aanvragen />
				</Fragment>} />
				<Route path="/veelgestelde-vragen.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | Antwoorden op de Meestgestelde Vragen</title>
						<meta name="description" content="Heb je vragen over Inschrijving, Intake of bevalling? Wij helpen je graag verder!" />
					</Helmet>
					<Faq />
				</Fragment>} />
				<Route path="/contact.html" element={<Fragment>
					<Helmet>
						<title>Kraamzorg Mama | Onze Contactgegevens Op een Rij!</title>
						<meta name="description" content="Je kunt op diverse manier contact met ons opnemen. " />
					</Helmet>
					<Contact />
				</Fragment>} />
				<Route path="/kraamverzorgende-denhaag" element={<Fragment>
					<Helmet>
						<title>Vacature Kraamverzorgende Den Haag | Werken Bij De Beste Kraambureau? ❤️</title>
						<meta name="description" content="Werken bij de beste kraambureau van NL? Bekijk de video op onze site om een indruk te krijgen van onze bureau." />
					</Helmet>
					<Vacancy place={'Den Haag'} />
				</Fragment>} />
				<Route path="/kraamverzorgende" element={<Fragment>
					<Helmet>
						<title>Vacature Kraamverzorgende | Werken Bij De Beste Kraambureau? ❤️</title>
						<meta name="description" content="Werken bij de beste kraambureau van NL? Bekijk de video op onze site om een indruk te krijgen van onze bureau." />
					</Helmet>
					<VacancyNew place={''} />
				</Fragment>} />
				<Route path="/kraamverzorgende-rotterdam" element={<Fragment>
					<Helmet>
						<title>Vacature Kraamverzorgende Rotterdam | Werken Bij De Beste Kraambureau? ❤️</title>
						<meta name="description" content="Werken bij de beste kraambureau van NL? Bekijk de video op onze site om een indruk te krijgen van onze bureau." />
					</Helmet>
					<Vacancy place={'Rotterdam'} />
				</Fragment>} />
				<Route path="/kraamverzorgende-utrecht" element={<Fragment>
					<Helmet>
						<title>Vacature Kraamverzorgende Utrecht | Werken Bij De Beste Kraambureau? ❤️</title>
						<meta name="description" content="Werken bij de beste kraambureau van NL? Bekijk de video op onze site om een indruk te krijgen van onze bureau." />
					</Helmet>
					<Vacancy place={'Utrecht'} />
				</Fragment>} />
				<Route path="/vacature-leerling.html" element={<Fragment>
					<Helmet>
						<title>Vacature Kraamverzorgende | Werken Bij De Beste Kraambureau? ❤️</title>
						<meta name="description" content="Werken bij de beste kraambureau van NL? Bekijk de video op onze site om een indruk te krijgen van onze bureau." />
					</Helmet>
					<VacancyLeerling />
				</Fragment>} />
				<Route path="/solliciteer.html" element={<Fragment>
					<Helmet>
						<title>Solliciteer</title>
						<meta name="robots" content="none" />
					</Helmet>
					<ApplyForJob />
				</Fragment>} />
				<Route path="/bedankt-aanvraag.html" element={<Fragment>
					<Helmet>
						<title>Bedankt voor jouw aanvraag</title>
						<meta name="robots" content="none" />
					</Helmet>
					<Bedankt conversion={false} />
				</Fragment>} />
				<Route path="/bedankt-voor-jouw-aanvraag.html" element={<Fragment>
					<Helmet>
						<title>Bedankt voor jouw aanvraag</title>
						<meta name="robots" content="none" />
					</Helmet>
					<Bedankt conversion={true} />
				</Fragment>} />
				<Route path="/goed-nieuws.html/:zipcode" element={<Fragment>
					<Helmet>
						<title>Goed nieuws!</title>
						<meta name="robots" content="none" />
					</Helmet>
					<Aanvragen showZipcodeMessage={true} />
				</Fragment>} />
				<Route path="/buiten-werkgebied.html" element={<Fragment>
					<Helmet>
						<title>Buiten werkgebied</title>
						<meta name="robots" content="none" />
					</Helmet>
					<NotWorkingZipcode />
				</Fragment>} />
				<Route path="/algemene-voorwaarden.html" element={<Fragment>
					<Helmet>
						<title>Algemene voorwaarden</title>
						<meta name="robots" content="none" />
					</Helmet>
					<GeneralConditions />
				</Fragment>} />
				<Route path="/privacy.html" element={<Fragment>
					<Helmet>
						<title>Privacy</title>
						<meta name="robots" content="none" />
					</Helmet>
					<Privacy />
				</Fragment>} />
				<Route path="/mamapas" element={<Fragment>
					<Helmet>
						<title>De Beste Kraamzorg In Jouw Regio | Hoogst Beoordeeld in NL ⭐⭐⭐⭐⭐</title>
						<meta name="description" content="Opzoek Naar De Beste Kraamzorg? Met 1.5k+ Beoordelingen De Hoogst Beoordeelde Kraambureau. Let Op! Beperkt Aantal Plaatsen. " />
					</Helmet>
					<Home />
				</Fragment>} />
				<Route path="/bedankt-voor-je-sollicitatie.html" element={<Fragment>
					<Helmet>
						<title>Bedankt voor je sollicitatie</title>
						<meta name="robots" content="none" />
					</Helmet>
					<BedanktSollicitation />
				~</Fragment>} />
				<Route path="/*" element={<Page404 /> } />
		   </Route>
           
       </Routes>
    </div>
  );
}





export default App;
