import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return <Fragment>
        <div className="md:container-fluid bg-pink">
			<div className="container mx-auto py-8 px-4">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
					<div className="">
						<FooterLinkHeader
							icon='/assets/images/footer-home.svg' 
							alt='Op onze website'>Op onze website</FooterLinkHeader>
						<FooterLinkGroup>
							<FooterLink href={'/veelgestelde-vragen.html'}>Vraag & antwoord</FooterLink>
							<FooterLink href={'/wie-zijn-wij.html'}>Wie zijn wij?</FooterLink>
							<FooterLink href={'/ervaringen'}>Ervaringen van andere moeders</FooterLink>
							<FooterLink href={'/intakegesprek.html'}>Intake & kraamtijd</FooterLink>
							<FooterLink href={'/verzekering.html'}>Kosten & verzekering</FooterLink>
							<FooterLink href={'/kraamzorg-aanvragen.html'}>Direct aanmelden</FooterLink>
							<FooterLink href={'/contact.html'}>Contact</FooterLink>
						</FooterLinkGroup>
					</div>
					<div className="">
						<FooterLinkHeader
							icon='/assets/images/footer-vacatures.svg' 
							alt='Vacatures'>Vacatures</FooterLinkHeader>
						<FooterLinkGroup>
							<FooterLink href={`/kraamverzorgende`}>Vacature kraamverzorgende</FooterLink>
							{/* <FooterLink href={`/kraamverzorgende-denhaag`}>Vacature kraamverzorgende Den Haag</FooterLink>
							<FooterLink href={`/kraamverzorgende-rotterdam`}>Vacature kraamverzorgende Rotterdam</FooterLink>
							<FooterLink href={`/kraamverzorgende-utrecht`}>Vacature kraamverzorgende Utrecht</FooterLink> */}
							<FooterLink href={`/vacature-leerling.html`}>Vacature leerling kraamverzorgende</FooterLink>
						</FooterLinkGroup>
						<FooterLinkHeader
							icon='/assets/images/footer-verzorgingsgebied.svg' 
							alt='Ons verzorgingsgebied'>Ons verzorgingsgebied</FooterLinkHeader>
						<FooterLinkGroup>
							<FooterLink href={`/kraamzorg-denhaag`}>Kraamzorg Den Haag en omgeving</FooterLink>
							<FooterLink href={`/kraamzorg-rotterdam`}>Kraamzorg Rotterdam en omgeving</FooterLink>
						</FooterLinkGroup>
					</div>
					<div className="">
						<FooterLinkHeader
							icon='/assets/images/footer-vragen.svg' 
							alt='Vragen? Zo kunt je ons bereiken!'>Vragen? Zo kun je ons bereiken!</FooterLinkHeader>	
						<FooterLinkGroup>
							<FooterLink hrefType="external" href="tel:0887110100" icon='/assets/images/footer-small-phone.svg'>088 - 711 0100</FooterLink>
							<FooterLink hrefType="external" href={`mailto:info@kraamzorgmama.nl`} icon='/assets/images/footer-small-mail.svg'>info@kraamzorgmama.nl</FooterLink>
							<FooterLink hrefType="external" href={`https://www.facebook.com/kraamzorgmama.nl/`} icon='/assets/images/footer-small-facebook.svg'>volg ons op Facebook</FooterLink>
							<FooterLink hrefType="external" href={`https://www.instagram.com/kraamzorgmama.nl/`} icon='/assets/images/footer-small-instagram.svg'>volg ons op Instagram</FooterLink>
						</FooterLinkGroup>
					</div>
				</div>
			</div>
		</div>


		<div className="md:container-fluid py-4 border-b">
			<div className="container mx-auto">
				<div className="flex md:place-content-center">
				
					<div><img alt="hkz" src="/assets/images/homepage-image-footer-hkz.png" className="px-1 md:px-4 object-scale-down"/></div>
					<div><img alt="zorg voor borstvoeding" src="/assets/images/homepage-image-footer-zorgvoorborstvoeding.png"  className="px-1 md:px-4 object-scale-down"/></div>
					<div><img alt="lid van bo" src="/assets/images/homepage-image-footer-llidvan.png"  className="px-1 md:px-4 object-scale-down"/></div>
					
				
					<div><img alt="google" src="/assets/images/homepage-image-footer-google.png"  className="px-1 md:px-4 object-scale-down"/></div>
					<div><img alt="trustpilot" src="/assets/images/homepage-image-footer-trustpilot.png"  className="px-1 md:px-4 object-scale-down"/></div>
					<div><img alt="zorgkaart nederland" src="/assets/images/homepage-image-footer-zorgkaartnederland.png"  className="px-1 md:px-4 object-scale-down"/></div>
					
				</div>
			</div>
		</div>

		<div className="md:container-fluid py-4">
			<div className="container mx-auto text-center text-blue text-xs">
				<Link to="/algemene-voorwaarden.html" className="border-r px-1 md:px-4">Algemene voorwaarden</Link>
				<Link to="/privacy.html" className=" md:px-4">Privacy</Link>
			</div>
		</div>

		<div className="md:container-fluid pb-4">
			<div className="container mx-auto text-center text-xs ">
				&copy; 2010 - 2024 - Kraamzorg Mama
			</div>
		</div>
    </Fragment>
}

function FooterLinkHeader({ children, icon, href, alt }) {
	let image = '';
	if(icon) {
		image = <img src={icon} alt={alt || 'pictogram'} className="pr-4 h-fit self-center" />
	}
	if(href) {
		return <a href={href} className="font-ro-sans text-xl md:text-2xl text-white h-fit flex content-center block">{image}<div className="self-center">{children}</div></a>
	}
	return <div className="font-ro-sans text-xl md:text-2xl text-white flex content-center h-fit">{image}<div className="self-center">{children}</div></div>
}

function FooterLinkGroup({ children }) {
	return <ul className={`my-4`}>
		{children}
	</ul>
}

function FooterLink({ children, icon, href = 'javascript:;', hrefType = 'normal'}) {
	let imageClassName = "inline-block h-fit self-center ";
	imageClassName += icon ? " w-10" : " w-5";
	return <li className={`text-white py-2 flex content-center hover:underline`}>
		<div className={imageClassName} >
			<img alt="pictogram" src={icon || `/assets/images/footer-link-arrow.svg`}/>
		</div>
		{ hrefType == 'external' && <a className="test" href={href}>{children}</a> || <Link to={href}>{children}</Link>}
		
		
	</li>
}